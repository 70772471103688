import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getQueryParams } from "../../utils/httpRequest";
import { baseUrl, onPrepareHeaders } from "./helpers";
import { showNotification } from "../actions/notification";
import { DEFAULT_NOTIFICATION_PAYLOAD, NOTIFICATION_VARIANTS, SORT_OPTIONS } from "../../utils/applicationConstants";
import { requestCloseSlideOver } from "../actions/slide_over";
import { requestCloseModal } from "../actions/modal";
import { ANALYTIC_EVENTS, sendAnalytics } from "../../utils/analytics";
import { setProcessingState } from "../reducers/modalConfirmation";
import { lenderCallLogService } from "./lenderCallLogService";

export const defaultLenderOfferFilters = {
  page: 1,
  size: 10,
  sortBy: SORT_OPTIONS[1].id,
  searchTerm: "",
};

export const lenderOfferService = createApi({
  reducerPath: "lenderOfferApi",
  tagTypes: ["LenderOffer"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: onPrepareHeaders,
  }),
  endpoints: builder => ({
    getLenderOffers: builder.query({
      query: ({ loanId, lenderId }) =>
        `loans/${loanId}/lenders/${lenderId}/offers?${getQueryParams(defaultLenderOfferFilters)}`,
      providesTags: result =>
        result
          ? [
              ...result.lenderOffers.items.map(({ lenderOfferId: id }) => ({ type: "LenderOffer", id })),
              { type: "LenderOffer", id: "LIST" },
            ]
          : [{ type: "LenderOffer", id: "LIST" }],
    }),
    getLenderOffer: builder.query({
      query: ({ loanId, lenderId, lenderOfferId }) => `loans/${loanId}/lenders/${lenderId}/offers/${lenderOfferId}`,
      providesTags: (result, error, { lenderOfferId }) => [{ type: "LenderOffer", id: lenderOfferId }],
    }),
    addLenderOffer: builder.mutation({
      query: ({ loanId, lenderId, ...body }) => ({
        url: `loans/${loanId}/lenders/${lenderId}/offers`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "LenderOffer", id: "LIST" }],
      async onQueryStarted({ closeSlide = true }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LENDER_OFFER_CREATED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Lender offer has been created!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
          dispatch(lenderCallLogService.util.invalidateTags([{ type: "LenderCallLog", id: "LIST" }]));
          if (closeSlide) dispatch(requestCloseSlideOver());
        } catch (err) {
          /* empty */
        }
      },
    }),
    updateLenderOffer: builder.mutation({
      query: ({ loanId, lenderId, lenderOfferId, ...body }) => ({
        url: `loans/${loanId}/lenders/${lenderId}/offers/${lenderOfferId}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, { lenderOfferId }) => [{ type: "LenderOffer", id: lenderOfferId }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LENDER_OFFER_UPDATED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Lender offer has been updated!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
          dispatch(requestCloseSlideOver());
        } catch (err) {
          /* empty */
        }
      },
    }),
    deleteLenderOffer: builder.mutation({
      query({ loanId, lenderId, lenderOfferId }) {
        return {
          url: `loans/${loanId}/lenders/${lenderId}/offers/${lenderOfferId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "LenderOffer", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          dispatch(setProcessingState(true));
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LENDER_OFFER_DELETED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Lender offer has been deleted!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
          dispatch(lenderCallLogService.util.invalidateTags([{ type: "LenderCallLog", id: "LIST" }]));
          dispatch(requestCloseSlideOver());
          dispatch(requestCloseModal());
        } catch (err) {
          dispatch(setProcessingState(false));
        }
      },
    }),
  }),
});

export const {
  useGetLenderOffersQuery,
  useGetLenderOfferQuery,
  useAddLenderOfferMutation,
  useUpdateLenderOfferMutation,
  useDeleteLenderOfferMutation,
} = lenderOfferService;
