import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  lenderOfferTypes: [],
};

const lenderOfferTypesSlice = createSlice({
  name: "lenderOfferTypes",
  initialState,
  reducers: {
    populateLenderOfferTypes(state, action) {
      return { ...state, lenderOfferTypes: action.payload };
    },
  },
});

export const { populateLenderOfferTypes } = lenderOfferTypesSlice.actions;

export default lenderOfferTypesSlice.reducer;
