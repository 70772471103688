import { combineReducers } from "redux";
import { currentUser } from "./currentUser";
import { notification } from "./notification";
import { slideOver } from "./slide_over";
import { companies } from "./companies";
import { users } from "./users";
import { loans } from "./loans";
import { countries } from "./countries";
import { loanTypes } from "./loan_types";
import { loanStatuses } from "./loan_statuses";
import { propertyTypes } from "./property_types";
import { propertyUses } from "./property_uses";
import { valuesBasedOn } from "./values_base_on";
import { currencies } from "./currencies";
import { needLists } from "./need_lists";
import { lenders } from "./lenders";
import { borrowers } from "./borrowers";
import { referrers } from "./referrers";
import { lenderCallLogs } from "./lender_call_logs";
import { lenderCallLogStatuses } from "./lender_call_log_statuses";
import { lendingCompanies } from "./lending_companies";
import { lendingParameters } from "./lending_parameters";
import { states } from "./states";
import { timeZones } from "./timeZones";
import { modal } from "./modal";
import { contacts } from "./contacts";
import { lendingCompaniesLookup } from "./lending_companies_lookup";
import { guarantees } from "./guarantees";
import { contactForm } from "./contact_form";
import { loanSummaries } from "./loan_summaries";
import { employees } from "./employees";
import { employeeRoles } from "./employee_roles";
import { needsListTemplates } from "./needs_list_templates";
import { needsListTemplateDocuments } from "./needs_list_template_documents";
import { lendingCompanyLoanPrograms } from "./lending_company_loan_programs";
import { loanPrograms } from "./loan_programs";
import { notifications } from "./notifications";
import { loanCollaborators } from "./loan_collaborators";
import { loanSummaryNeedsListFiles } from "./loan_summary_needs_list_files";
import { lenderCallLogNotes } from "./lender_call_log_notes";
import modalConfirmation from "./modalConfirmation";
import lenderOfferTypeStore from "./lenderOfferTypes";
import rateTypeStore from "./rateTypes";
import rateValueTypeStore from "./rateValueTypes";
import prepaymentPenaltyTypeStore from "./prepaymentPenaltyValueTypes";
import mortgageIndexStore from "./morgateIndexes";
import lenderOfferFileStore from "./lender_offer_file";
import guaranteeStore from "./guarantees_v2";
import { serviceReducers } from "../services/index";

const reducers = {
  currentUser,
  notification,
  slideOver,
  companies,
  users,
  loans,
  countries,
  loanTypes,
  loanStatuses,
  propertyTypes,
  propertyUses,
  valuesBasedOn,
  currencies,
  needLists,
  lenders,
  borrowers,
  referrers,
  lenderCallLogs,
  lenderCallLogStatuses,
  lendingCompanies,
  lendingParameters,
  modal,
  states,
  timeZones,
  contacts,
  lendingCompaniesLookup,
  guarantees,
  contactForm,
  loanSummaries,
  employees,
  employeeRoles,
  needsListTemplates,
  needsListTemplateDocuments,
  lendingCompanyLoanPrograms,
  loanPrograms,
  notifications,
  loanCollaborators,
  loanSummaryNeedsListFiles,
  lenderCallLogNotes,
  modalConfirmation,
  lenderOfferTypeStore,
  rateTypeStore,
  rateValueTypeStore,
  prepaymentPenaltyTypeStore,
  mortgageIndexStore,
  lenderOfferFileStore,
  guaranteeStore,
};

export default combineReducers({ ...reducers, ...serviceReducers });
