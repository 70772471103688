import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  guarantees: [],
};

const guaranteesSlice = createSlice({
  name: "newGuarantees",
  initialState,
  reducers: {
    populateGuarantees(state, action) {
      return { ...state, guarantees: action.payload };
    },
  },
});

export const { populateGuarantees } = guaranteesSlice.actions;

export default guaranteesSlice.reducer;
