import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as lenderOfferFileApi from "../../api/lender_offer_file";
import { showNotification } from "../actions/notification";
import { DEFAULT_NOTIFICATION_PAYLOAD, NOTIFICATION_VARIANTS } from "../../utils/applicationConstants";

const initialState = {
  isUploading: false,
  isProcessing: false,
  fileId: null,
};

export const uploadLenderOfferFile = createAsyncThunk(
  "users/fetchByIdStatus",
  async (request, { dispatch, getState }) => {
    const { companyId } = getState().currentUser.currentCompany;
    const { path, files, callback } = request;

    const response = await lenderOfferFileApi.createLenderOfferFile(companyId, path, files);

    if (response.ok) {
      dispatch(
        showNotification({
          ...DEFAULT_NOTIFICATION_PAYLOAD,
          message: {
            ...DEFAULT_NOTIFICATION_PAYLOAD.message,
            description: "File has been uploaded successfully!",
            options: {
              variant: NOTIFICATION_VARIANTS.SUCCESS,
            },
          },
        }),
      );
      if (callback) callback();
    } else {
      dispatch(
        showNotification({
          ...DEFAULT_NOTIFICATION_PAYLOAD,
          message: {
            title: "Error",
            description: response.data.userDescription,
            options: {
              variant: NOTIFICATION_VARIANTS.ERROR,
            },
          },
        }),
      );
    }

    return response;
  },
);

export const lenderOfferFileSlice = createSlice({
  name: "rateTypes",
  initialState,
  reducers: {
    setLenderOfferFileOnProcessingState(state, action) {
      return { ...state, isProcessing: true, fileId: action.payload.fileId };
    },
    clearLenderOfferFileOnProcessingState(state) {
      return { ...state, isProcessing: false, fileId: null };
    },
  },
  extraReducers: builder => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(uploadLenderOfferFile.fulfilled, state =>
      // Add user to the state array
      ({ ...state, isUploading: false }),
    );
    builder.addCase(uploadLenderOfferFile.pending, state => ({ ...state, isUploading: true }));
    builder.addCase(uploadLenderOfferFile.rejected, state => ({ ...state, isUploading: false }));
  },
});

export const { setLenderOfferFileOnProcessingState, clearLenderOfferFileOnProcessingState } =
  lenderOfferFileSlice.actions;

export default lenderOfferFileSlice.reducer;
