import React, { Fragment, useCallback, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import dynamic from "next/dynamic";
import classNames from "classnames";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import { requestCloseSlideOver } from "../../redux/actions/slide_over";
import { SLIDE_OVER } from "../../utils/applicationConstants";
import styles from "./styles.module.sass";

const CurrentCompanyForm = dynamic(() => import("../company/form/current_company_form").then(c => c.default), {
  ssr: false,
});
const UserForm = dynamic(() => import("../contact/forms/contact_form").then(c => c.default), { ssr: false });
const NeedListForm = dynamic(() => import("../forms/need_list_form/need_list_form").then(c => c.default), {
  ssr: false,
});
const NeedListFormFile = dynamic(() => import("../forms/need_list_form/need_list_file_form").then(c => c.default), {
  ssr: false,
});
const MyNeedListFormFile = dynamic(
  () => import("../forms/need_list_form/my_needs_list_file_form").then(c => c.default),
  { ssr: false },
);
const DocumentForm = dynamic(() => import("../forms/document_form/document_form").then(c => c.default), { ssr: false });

const LenderCallLogForm = dynamic(
  () => import("../lenders_call_log/controls/lender_call_log_form").then(c => c.default),
  { ssr: false },
);
const LendingCompanyForm = dynamic(
  () => import("../forms/lending_company_form/lending_company_form").then(c => c.default),
  { ssr: false },
);
const LoanCategoryFilterForm = dynamic(() => import("../category_filters/loans_category_filter").then(c => c.default), {
  ssr: false,
});
const ContactCategoryFilterForm = dynamic(
  () => import("../category_filters/contacts_category_filter").then(c => c.default),
  { ssr: false },
);
const LendingCompanyCategoryFilterForm = dynamic(
  () => import("../category_filters/lending_companies_category_filter").then(c => c.default),
  { ssr: false },
);
const LoanSummaryList = dynamic(() => import("../loan_summary/list/loan_summary_list").then(c => c.default), {
  ssr: false,
});
const EmployeesRolesFilter = dynamic(() => import("../employees/forms/employee_category_filter").then(c => c.default), {
  ssr: false,
});
const EmployeeForm = dynamic(() => import("../employees/forms/employee_form").then(c => c.default), { ssr: false });
const NeedsListTemplateForm = dynamic(
  () => import("../needs_list_templates/forms/needs_list_template_form").then(c => c.default),
  { ssr: false },
);
const CloneNeedsListTemplateForm = dynamic(
  () => import("../needs_list_templates/forms/clone_needs_list_template_form").then(c => c.default),
  { ssr: false },
);
const NeedsListAddFromTemplate = dynamic(
  () => import("../needs_list_templates/forms/needs_list_add_from_template_form").then(c => c.default),
  { ssr: false },
);
const NeedsListAddFromBorrowerNeedsList = dynamic(
  () => import("../needs_list_templates/forms/needs_list_template_from_borrower_form").then(c => c.default),
  { ssr: false },
);
const LoanCollaborators = dynamic(() => import("../loan/loan_collaborators/loan_collaborators").then(c => c.default), {
  ssr: false,
});
const CreatableOnlyContactForm = dynamic(() => import("../contact/forms/creatable_contact_form").then(c => c.default), {
  ssr: false,
});
const BrokerBorrowerNeedsListFileForm = dynamic(
  () => import("../loan/broker/broker_needs_list_file_form").then(c => c.default),
  {
    ssr: false,
  },
);

const BorrowerNeedsListFileForm = dynamic(
  () => import("../loan/borrower/borrower_needs_list_file_form").then(c => c.default),
  {
    ssr: false,
  },
);

export function SlideOver({ children }) {
  const { isOpen: isSlideOverOpen, fragment, options } = useSelector(state => state.slideOver);
  const { isOpen: isModalOpen } = useSelector(state => state.modal);
  const { notifications } = useSelector(state => state.notification);
  const dispatch = useDispatch();
  const focus = useRef();

  const handleClose = useCallback(() => {
    if (!notifications.length) dispatch(requestCloseSlideOver());
  }, [dispatch, notifications]);

  const handleCloseCallback = useCallback(() => {
    dispatch(requestCloseSlideOver());
  }, [dispatch]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (isModalOpen && !isSlideOverOpen) return <>{children}</>;

  return (
    <Transition.Root show={isSlideOverOpen} as={Fragment}>
      <Dialog as="div" className={classNames("relative", styles.zIndex)} onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 dark:bg-none bg-opacity-75 dark:bg-opacity-30 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel
                  className={classNames("pointer-events-auto w-screen", {
                    "max-w-2xl": options?.size === "LG",
                    "max-w-md": isEmpty(options?.size),
                  })}
                >
                  <div className="relative h-full flex-1">
                    {/* Replace with your content */}
                    <div className="absolute inset-0">
                      <div className="h-full" aria-hidden="true">
                        <div
                          ref={focus}
                          className={classNames(
                            "h-full flex flex-col bg-white dark:bg-slate-800 shadow-xl overflow-y-scroll",
                            styles.slideOverContainer,
                          )}
                        >
                          {fragment === SLIDE_OVER.COMPANY && <CurrentCompanyForm handleClose={handleCloseCallback} />}
                          {fragment === SLIDE_OVER.USER && <UserForm handleClose={handleCloseCallback} />}
                          {fragment === SLIDE_OVER.NEED_LIST && <NeedListForm handleClose={handleCloseCallback} />}
                          {fragment === SLIDE_OVER.NEED_LIST_FILE && (
                            <NeedListFormFile handleClose={handleCloseCallback} />
                          )}
                          {fragment === SLIDE_OVER.MY_NEED_LIST_FILE && (
                            <MyNeedListFormFile handleClose={handleCloseCallback} />
                          )}
                          {fragment === SLIDE_OVER.DOCUMENT && <DocumentForm handleClose={handleCloseCallback} />}
                          {fragment === SLIDE_OVER.LENDING_COMPANY && (
                            <LendingCompanyForm handleClose={handleCloseCallback} />
                          )}
                          {fragment === SLIDE_OVER.LENDER_CALL_LOG && (
                            <LenderCallLogForm handleClose={handleCloseCallback} />
                          )}
                          {fragment === SLIDE_OVER.LOAN_CATEGORY_FILTER && (
                            <LoanCategoryFilterForm handleClose={handleCloseCallback} />
                          )}
                          {fragment === SLIDE_OVER.CONTACTS_CATEGORY_FILTER && (
                            <ContactCategoryFilterForm handleClose={handleCloseCallback} />
                          )}
                          {fragment === SLIDE_OVER.LENDING_COMPANY_CATEGORY_FILTER && (
                            <LendingCompanyCategoryFilterForm handleClose={handleCloseCallback} />
                          )}
                          {fragment === SLIDE_OVER.LOAN_SUMMARY_LIST && (
                            <LoanSummaryList handleClose={handleCloseCallback} />
                          )}
                          {fragment === SLIDE_OVER.EMPLOYEE_ROLES_FILTER && (
                            <EmployeesRolesFilter handleClose={handleCloseCallback} />
                          )}
                          {fragment === SLIDE_OVER.EMPLOYEE_FORM && <EmployeeForm handleClose={handleCloseCallback} />}
                          {fragment === SLIDE_OVER.NEEDS_LIST_TEMPLATE_FORM && (
                            <NeedsListTemplateForm handleClose={handleCloseCallback} />
                          )}
                          {fragment === SLIDE_OVER.CLONE_NEEDS_LIST_TEMPLATE_FORM && (
                            <CloneNeedsListTemplateForm handleClose={handleCloseCallback} />
                          )}
                          {fragment === SLIDE_OVER.NEEDS_LIST_ADD_FROM_TEMPLATE && (
                            <NeedsListAddFromTemplate handleClose={handleCloseCallback} />
                          )}
                          {fragment === SLIDE_OVER.CREATE_NEEDS_LIST_TEMPLATE_FROM_BORROWER_NEEDS_LIST && (
                            <NeedsListAddFromBorrowerNeedsList handleClose={handleCloseCallback} />
                          )}
                          {fragment === SLIDE_OVER.LOAN_COLLABORATORS && (
                            <LoanCollaborators handleClose={handleCloseCallback} />
                          )}
                          {fragment === SLIDE_OVER.CREATABLE_ONLY_CONTACT_FORM && (
                            <CreatableOnlyContactForm handleClose={handleCloseCallback} />
                          )}
                          {fragment === SLIDE_OVER.BROKER_BORROWER_NEEDS_LIST_FILE_FORM && (
                            <BrokerBorrowerNeedsListFileForm handleClose={handleCloseCallback} />
                          )}
                          {fragment === SLIDE_OVER.BORROWER_NEEDS_LIST_FILE_FORM && (
                            <BorrowerNeedsListFileForm handleClose={handleCloseCallback} />
                          )}
                          {children}
                        </div>
                      </div>
                    </div>
                    {/* /End replace */}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

SlideOver.propTypes = {
  children: PropTypes.node.isRequired,
};
