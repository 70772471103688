import { fetchWithHeaders, HTTP_METHODS, parseErrorResponse, responseData } from "../utils/httpRequest";

export function fetchLoanPrograms() {
  return fetchWithHeaders("/parameters/loan-programs", {
    method: HTTP_METHODS.GET,
  })
    .then(responseData)
    .catch(parseErrorResponse);
}

export function fetchCountries() {
  return fetchWithHeaders("/parameters/countries", {
    method: HTTP_METHODS.GET,
  })
    .then(responseData)
    .catch(parseErrorResponse);
}

export function fetchStates(countryId) {
  return fetchWithHeaders(`/parameters/countries/${countryId}/states`, {
    method: HTTP_METHODS.GET,
  })
    .then(responseData)
    .catch(parseErrorResponse);
}

export function fetchCurrencies() {
  return fetchWithHeaders("/parameters/currencies", {
    method: HTTP_METHODS.GET,
  })
    .then(responseData)
    .catch(parseErrorResponse);
}

export function fetchTimeZones() {
  return fetchWithHeaders("/parameters/time-zones", {
    method: HTTP_METHODS.GET,
  })
    .then(responseData)
    .catch(parseErrorResponse);
}

export function fetchLenderOfferTypes() {
  return fetchWithHeaders("/parameters/lender-offer-types", {
    method: HTTP_METHODS.GET,
  })
    .then(responseData)
    .catch(parseErrorResponse);
}

export function fetchRateTypes() {
  return fetchWithHeaders("/parameters/rate-types", {
    method: HTTP_METHODS.GET,
  })
    .then(responseData)
    .catch(parseErrorResponse);
}

export function fetchRateValueTypes() {
  return fetchWithHeaders("/parameters/rate-value-types", {
    method: HTTP_METHODS.GET,
  })
    .then(responseData)
    .catch(parseErrorResponse);
}

export function fetchPrepaymentPenaltyTypes() {
  return fetchWithHeaders("/parameters/prepayment-penalty-types", {
    method: HTTP_METHODS.GET,
  })
    .then(responseData)
    .catch(parseErrorResponse);
}

export function fetchMortgageIndexes() {
  return fetchWithHeaders("/parameters/mortgage-indexes", {
    method: HTTP_METHODS.GET,
  })
    .then(responseData)
    .catch(parseErrorResponse);
}

export function fetchGuarantees() {
  return fetchWithHeaders("/parameters/guarantees", {
    method: HTTP_METHODS.GET,
  })
    .then(responseData)
    .catch(parseErrorResponse);
}
