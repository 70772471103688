import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  prepaymentPenaltyTypes: [],
};

const prepaymentPenaltyTypesSlice = createSlice({
  name: "prepaymentPenaltyTypes",
  initialState,
  reducers: {
    populatePrepaymentPenaltyTypes(state, action) {
      return { ...state, prepaymentPenaltyTypes: action.payload };
    },
  },
});

export const { populatePrepaymentPenaltyTypes } = prepaymentPenaltyTypesSlice.actions;

export default prepaymentPenaltyTypesSlice.reducer;
