import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mortgageIndexes: [],
};

const mortgageIndexesSlice = createSlice({
  name: "mortgageIndexes",
  initialState,
  reducers: {
    populateMortgageIndexes(state, action) {
      return { ...state, mortgageIndexes: action.payload };
    },
  },
});

export const { populateMortgageIndexes } = mortgageIndexesSlice.actions;

export default mortgageIndexesSlice.reducer;
