import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  rateTypes: [],
};

const rateTypesSlice = createSlice({
  name: "rateTypes",
  initialState,
  reducers: {
    populateRateTypes(state, action) {
      return { ...state, rateTypes: action.payload };
    },
  },
});

export const { populateRateTypes } = rateTypesSlice.actions;

export default rateTypesSlice.reducer;
