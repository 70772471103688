import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  rateValueTypes: [],
};

const rateValueTypesSlice = createSlice({
  name: "rateValueTypes",
  initialState,
  reducers: {
    populateRateValueTypes(state, action) {
      return { ...state, rateValueTypes: action.payload };
    },
  },
});

export const { populateRateValueTypes } = rateValueTypesSlice.actions;

export default rateValueTypesSlice.reducer;
